import React, { useState, FocusEvent } from 'react';

import { RouteComponentProps } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import API from 'api/API';
import { Connector } from 'api/APITypes';
import BackButton from 'components/inputs/basic/Button/BackButton';
import Button from 'components/inputs/basic/Button/Button';
import Switch from 'components/inputs/basic/Switch/Switch';
import ListboxFormikGroup from 'components/inputs/formik_group/ListboxFormikGroup/ListboxFormikGroup';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import CenteredLayout from 'components/layouts/pages/CenteredLayout/CenteredLayout';
import ConnectorIcon from 'components/primitives/icons/ConnectorIcon/ConnectorIcon';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useUserProfile } from 'context/AuthContext';
import { valConnectorSchemaName, valConnectorTableName } from 'utils/Validators';

import { ConnectorType, fivetranConnectors } from '../ConnectorRegistry';
import { useGetConnectors, connectCardUrl } from '../ConnectorUtils';

// You can check the list again via the last line printed from backend/management/commands/reconcile_connector_config.py
const TIMEFRAME_MONTHS_CONNECTORS = [
  'adobe_analytics',
  'adroll',
  'apple_search_ads',
  'bingads',
  'criteo',
  'double_click_campaign_manager',
  'facebook_ads',
  'facebook',
  'google_ads',
  'google_analytics_4',
  'google_analytics_mcf',
  'google_analytics',
  'google_display_and_video_360',
  'google_search_console',
  'instagram_business',
  'itunes_connect',
  'outbrain',
  'pinterest_ads',
  'reddit_ads',
  'snapchat_ads',
  'taboola',
  'the_trade_desk',
  'tiktok_ads',
  'twitter_ads',
  'twitter',
  'yahoo_gemini',
];

const MERGE_MODE_CONNECTORS = ['box', 'dropbox', 'google_drive', 'share_point', 'one_drive'];

// These connectors require a schema to be in <schema>.<table> format if merge mode is enabled
// Note these connectors cannot be of syncScope 'table'
const MERGE_MODE_NEEDS_TABLE = ['box', 'google_drive', 'share_point', 'dropbox'];

const MERGE_MODE_REQUIRES_PREFIXED_SCHEMAS = ['google_drive'];

interface FormConfig {
  schema?: string;
  schema_prefix?: string;
  table?: string;
  timeframe_months?: string; // https://fivetran.com/docs/rest-api/connectors/config#googleanalytics4
  is_single_table_mode?: boolean; // https://fivetran.com/docs/rest-api/api-reference/connectors/create-connector?service=dropbox#is_single_table_mode
  prefix?: string;
}

interface APICreateConnectorRequestPayload {
  service: string;
  config: FormConfig;
  paused: boolean;
}

interface APICreateConnectorReponsePayload {
  mozart_connector_id: string;
  fivetran_connector_id: string;
  token: string;
}

interface MatchParams {
  service: string;
}

interface AddConnectorFormProps extends RouteComponentProps<MatchParams> {
  loading: boolean;
  error: string;
  connectorType: ConnectorType;
  onConnect(config: any): void;
  onCancel(): void;
}

export default function AddConnectorForm(props: AddConnectorFormProps) {
  useTitle('Add Connector');

  // On slow internet, Formik isSubmitting gets set back to false, and
  // the SpinnerButton rerenders with stoped spinning before the window changes location.
  // So, we are using our own isSubmitting that we don't set back to false
  // on window change location.
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [connectError, setConnectError] = useState('');

  const {
    userProfile: { company_role },
  } = useUserProfile();

  const navigate = useNavigate();

  // Call connector API and map the results to lists more useful to form validation
  const {
    isLoading: pageLoading,
    connectors,
    error: pageError,
  } = useGetConnectors('There was a problem loading this page.');
  const existingSchemas = connectors.map((c: Connector) => c.schema);

  // Look up connectorType by service key and abort early if not found
  const service = props.match.params.service;
  const connectorType = fivetranConnectors[service];
  if (!connectorType) {
    return (
      <CenteredLayout
        maxWidth="60%"
        title={`Add ${service} connector`}
        loadingError="Unknown connector type"
        children={<></>}
      />
    );
  }
  const { name, suggestedSchema, syncScope, startPaused } = connectorType;

  // Init Formik
  const initialValues: FormConfig = {};
  const validators: any = {};

  if (syncScope === 'table') {
    // table syncScope means we want to ask for table AND schema
    initialValues.schema = suggestedSchema;
    initialValues.table = '';
    validators.table = valConnectorTableName('Table', suggestedSchema, existingSchemas);
    validators.schema = valConnectorSchemaName('Schema', existingSchemas);
  } else {
    if (syncScope === 'prefixed_schemas') {
      initialValues.schema_prefix = suggestedSchema;
      validators.schema_prefix = valConnectorSchemaName('Schema prefix', existingSchemas);
    } else {
      initialValues.schema = suggestedSchema;
      validators.schema = valConnectorSchemaName('Schema', existingSchemas);
    }
  }
  // If merge mode is enabled, we need to make sure the table is in <schema>.<table> format for some connectors
  if (MERGE_MODE_NEEDS_TABLE.includes(service)) {
    initialValues.table = '';
    validators.is_single_table_mode = yup.boolean().notRequired();
    validators.table = yup.string().when('is_single_table_mode', (isSingleTableMode, schema) => {
      // isSingleTableMode is an array for some reason
      if (isSingleTableMode[0]) {
        // Apply custom validation function when is_single_table_mode is true
        return valConnectorTableName('Table', suggestedSchema, existingSchemas);
      }
      // Field is not required if is_single_table_mode is false
      return schema.notRequired();
    });
  }
  const submitSchema = yup.object(validators);
  if (TIMEFRAME_MONTHS_CONNECTORS.includes(service)) {
    initialValues.timeframe_months = 'THREE';
  }
  if (MERGE_MODE_CONNECTORS.includes(service)) {
    initialValues.is_single_table_mode = false;
  }

  const handleConnect = async (config: FormConfig, formikHelpers: FormikHelpers<FormConfig>) => {
    // If merge mode is enabled, we need to make sure the table is in <schema>.<table> format for some connectors otherwise delete it
    if (MERGE_MODE_NEEDS_TABLE.includes(service) && !config.is_single_table_mode && config.table) {
      delete config.table;
    }
    // Some connectors require prefixed schema instead of one schema when in merge mode, but it can be empty and input in Fivetran UI
    if (MERGE_MODE_REQUIRES_PREFIXED_SCHEMAS.includes(service) && config.is_single_table_mode) {
      config.prefix = '';
    }

    const newConnectorProps: APICreateConnectorRequestPayload = {
      service,
      config,
      paused: Boolean(startPaused || syncScope === 'prefixed_schemas'),
    };

    const api = new API();
    setIsSubmitting(true);
    setConnectError('');

    try {
      const response = await api.post('api/fivetran/create_connector', newConnectorProps);
      const newConnectorIDs = response.data as APICreateConnectorReponsePayload;
      analytics.track('AddConnectorForm Connect');
      window.location.href = connectCardUrl(
        newConnectorIDs.mozart_connector_id,
        newConnectorIDs.token,
        newConnectorProps.paused,
      );
    } catch (error) {
      setIsSubmitting(false);
      setConnectError('Failed to create connector.');
      formikHelpers.setSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate('/connectors/add');
  };

  const header = (
    <div className="f-between">
      <div className="flex items-center">
        <ConnectorIcon service={service} size={36} />
        <div className="display-base ml-2">{name}</div>
      </div>
      <BackButton text="ALL CONNECTORS" onClick={handleCancel} data-track="AddConnectorForm Cancel" />
    </div>
  );

  return (
    <CenteredLayout maxWidth="814px" header={header} loading={pageLoading} loadingError={pageError}>
      <Formik validationSchema={submitSchema} onSubmit={handleConnect} initialValues={initialValues}>
        {({ handleSubmit, handleChange, setFieldTouched, values, isValid }) => {
          // Don't let unnoticed spaces mess up input validation
          const trimmedChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            event.target.value = event.target.value.trim();
            setFieldTouched(event.target.name, true, false); // Explicit parameters prevent touched? errors
            handleChange(event);
          };

          let destinationType = 'table';
          if (syncScope !== 'table') {
            destinationType = syncScope === 'prefixed_schemas' ? 'schema prefix' : 'schema';
          }
          const addButtonText = 'Add Connector';

          return (
            <form noValidate onSubmit={handleSubmit}>
              {company_role === 'viewer' && (
                <Alert variant="info" className="my-4">
                  Viewers cannot add connectors. Please contact an admin to add a connector.
                </Alert>
              )}
              <div className="bg-pri-gray-50 p-4 rounded">
                <div className="text-xl font-medium">Connection Instructions</div>
                <ul className="ml-4 list-disc">
                  <li>Enter a destination {destinationType}.</li>
                  <li>
                    After you click {addButtonText}, you will be forwarded to Fivetran to complete setup.
                  </li>
                  <li>
                    After you click Save & Test on Fivetran, you will be returned to Mozart and your data
                    will begin syncing immediately. Your data will be available in Mozart as soon as
                    syncing is complete.
                  </li>
                </ul>
              </div>
              {syncScope === 'table' && (
                <>
                  <ConnectorInput
                    name="schema"
                    infoTip="All the data from this connector will be synced to a single table in the schema and table you enter here."
                    disabled={company_role === 'viewer'}
                    onChange={trimmedChange}
                  />
                  <ConnectorInput
                    name="table"
                    infoTip="All the data from this connector will be synced to a single table in the schema and table you enter here."
                    disabled={company_role === 'viewer'}
                    onChange={trimmedChange}
                  />
                </>
              )}
              {syncScope === 'prefixed_schemas' && (
                <ConnectorInput
                  name="schema_prefix"
                  infoTip={`Databases are a collection of schemas. All schemas synced from ${name} will be prefixed with the prefix you enter here. For example, if you enter the prefix "happy" and your database has a schema named "days" the schema will be synced to Mozart as "happy_days". At the end of setup, you will be given the chance to choose which schemas and tables to sync.`}
                  disabled={company_role === 'viewer'}
                  onChange={trimmedChange}
                />
              )}
              {syncScope === 'one_schema' && (
                <ConnectorInput
                  name="schema"
                  infoTip={`Schemas are a collection of tables. This connector will sync all tables from ${name} to the schema entered here.`}
                  disabled={company_role === 'viewer'}
                  onChange={trimmedChange}
                />
              )}
              {TIMEFRAME_MONTHS_CONNECTORS.includes(service) && (
                <ListboxFormikGroup
                  label="Sync timeframe"
                  name="timeframe_months"
                  options={[
                    { label: 'Three months', value: 'THREE' },
                    { label: 'Six months', value: 'SIX' },
                    { label: 'Twelve months', value: 'TWELVE' },
                    { label: 'Twenty-four months', value: 'TWENTY_FOUR' },
                    { label: 'All time', value: 'ALL_TIME' },
                  ]}
                  groupClass="mt-1"
                  postLabelElement={
                    <InfoIcon
                      content="The number of months of reporting data you'd like to include in your initial sync. This cannot be modified once the connector is created."
                      containerClass="ml-1"
                      popoverProps={{ style: { maxWidth: '700px' } }}
                    />
                  }
                  disabled={company_role === 'viewer'}
                />
              )}
              {MERGE_MODE_CONNECTORS.includes(service) && (
                <div className="mt-4">
                  <div className="f-row-y-center">
                    <div className="block text-input-label text-nowrap">Merge Mode</div>
                    <InfoIcon
                      content="Merge Mode syncs data from all files in the configured source folder into a single destination table. If Merge Mode is disabled, each file will sync to its own table."
                      containerClass="ml-1"
                    />
                  </div>
                  <Switch
                    id="is_single_table_mode"
                    name="is_single_table_mode"
                    checked={values.is_single_table_mode}
                    onChange={handleChange}
                    disabled={company_role === 'viewer'}
                  />
                </div>
              )}
              {MERGE_MODE_NEEDS_TABLE.includes(service) && values.is_single_table_mode && (
                <TextFormikGroup
                  name="table"
                  label="Destination Table"
                  onChange={trimmedChange}
                  autoFocus
                  onFocus={(e: FocusEvent<HTMLInputElement>) => e.currentTarget.select()}
                  groupClass="mt-4"
                />
              )}
              {connectError && (
                <Alert variant="error" className="mt-4">
                  {connectError}
                </Alert>
              )}
              <div className="f-row-y-center mt-4">
                <Button
                  type="submit"
                  variant="lightAction"
                  spinning={isSubmitting}
                  disabled={!isValid || company_role === 'viewer'}
                  style={{ width: '214px' }}
                >
                  {addButtonText}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </CenteredLayout>
  );
}

interface ConnectorInputProps {
  name: string;
  infoTip: string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ConnectorInput = (props: ConnectorInputProps) => {
  const { name, infoTip, disabled, onChange } = props;

  return (
    <TextFormikGroup
      name={name}
      label={`Destination ${name.split('_').join(' ')}`}
      postLabelElement={
        <InfoIcon
          content={infoTip}
          containerClass="ml-1"
          popoverProps={{ style: { maxWidth: '700px' } }}
        />
      }
      onChange={onChange}
      autoFocus
      onFocus={(e: FocusEvent<HTMLInputElement>) => e.currentTarget.select()}
      groupClass="mt-4"
      disabled={disabled}
    />
  );
};
